<template>
<div>

    <b-row>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-chart-bar fa-md"></i> <span class="h5"> Estadísticas</span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">ESTADÍSTICAS DEL SISTEMA SOBRE SIMULACROS
                    </span>
                    <hr>
                    <b-row class="justify-content-center text-center">
                        <b-col cols="12" class=" my-2 icon-custom">
                            <i style="inline-size: auto" class="ico fas fa-paste fa-3x"></i> <br>
                            <span class="lg-numero">{{listaSimulacros.length}}</span>
                            <br>
                            <span class="text-muted">Simulacros</span>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-cog fa-md"></i> <span class="h5"> Gestión de Simulacros</span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="justify-content-center text-center">
                        <b-col v-if="checkPermissions('004-14001-SIM','c') == 1" cols="4" class="my-2">
                            <b-button block variant="custom" @click="modalRegistrarSimulacro = true">
                                <i style="inline-size: auto" class="fas fa-plus-circle fa-3x pb-1"></i><br>Nuevo simulacro
                            </b-button>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-list fa-md"></i> <span class="h5"> Simulacros registrados</span>
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <b-col lg="2">
                            <b-form-group label="Registros por página" class="text-muted">
                                <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col lg="7">
                        </b-col>
                        <b-col lg="3">
                            <b-form-group label="Busqueda:" class="text-muted">
                                <b-input-group size="xl">
                                    <b-input-group-prepend is-text>
                                        <i class="fas fa-search  my-0 mx-0"></i>
                                    </b-input-group-prepend>
                                    <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-table :items="listaSimulacros" :fields="camposSimulacro" bordered :per-page="porPagina" :current-page="currentPage" @filtered="onFiltered" :filter="filter" :filter-included-fields="filterOn" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                <template #cell(opciones)="param">
                                    <b-button v-if="checkPermissions('004-14001-SIM','u') == 1" @click="abrirModalActualizarSimulacro(param)" class="mr-1 mb-1" size="sm" variant="dark" v-c-tooltip="'Ver detalles'">
                                        <i class="fas fa-search  my-0 mx-0"></i>
                                    </b-button>
                                    <b-button @click="generarInforme(param)" :disabled="disabled" class="mr-1 mb-1" size="sm" variant="blue" v-c-tooltip="'Informe de simulacro'">
                                        <i class="fas fa-download my-0 mx-0"></i>
                                    </b-button>
                                    <b-button v-if="checkPermissions('004-14001-SIM','d') == 1" @click="eliminarSimulacro(param)" size="sm" class="mr-1 mb-1" variant="danger" v-c-tooltip="'Eliminar'">
                                        <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                    </b-button>
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>
                </CCardBody>
                <CCardFooter>
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="porPagina" align="right" size="sm" class="my-0"></b-pagination>
                </CCardfooter>
            </CCard>
        </b-col>
    </b-row>

    <CModal :closeOnBackdrop="false" color="primario" size="lg" :show.sync="modalRegistrarSimulacro">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Nuevo simulacro </span>
            </h6>
            <CButtonClose @click="modalRegistrarSimulacro = false" class="text-white" />
        </template>
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(registrarSimulacro)">
                <b-row>
                    <b-col md="3">
                        <validation-provider name="Simulacro N°" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Simulacro N°:" class="mb-2">
                                <b-form-input disabled v-model="datosSimulacro.numeroSimulacro" type="text" :state="getValidationState(validationContext)"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="9">
                        <validation-provider name="incidente" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Incidente:" class="mb-2">
                                <b-form-input v-model="datosSimulacro.incidente" type="text" :state="getValidationState(validationContext)" placeholder="Ingrese el incidente"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="actuaciones desarrolladas" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Actuaciones desarrolladas:" class="mb-2">
                                <b-form-textarea v-model="datosSimulacro.actuaciones" rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Ingrese las actuaciones"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="Desviaciones / Deficiencias / Daños detectados" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Desviaciones / Deficiencias / Daños detectados:" class="mb-2">
                                <b-form-textarea v-model="datosSimulacro.desviaciones" rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Ingrese las desviaciones"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4">
                        <validation-provider name="fecha" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Fecha:" class="mb-2">
                                <b-form-input v-model="datosSimulacro.fecha" type="date" :state="getValidationState(validationContext)"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4">
                        <validation-provider name="hora" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Hora:" class="mb-2">
                                <b-form-input v-model="datosSimulacro.hora" type="time" :state="getValidationState(validationContext)"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4">
                        <validation-provider name="responsable" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Responsable:" class="mb-2">
                                <b-form-input v-model="datosSimulacro.responsable" type="text" :state="getValidationState(validationContext)" placeholder="Ingrese el responsable"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="Medidas correctoras / Sugerencias" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Medidas correctoras / Sugerencias:" class="mb-2">
                                <b-form-textarea v-model="datosSimulacro.medidas" rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Ingrese las medidas correctivas"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="observaciones" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Observaciones:" class="mb-2">
                                <b-form-textarea v-model="datosSimulacro.observaciones" rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Ingrese las observaciones"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col class="my-2 text-right" md="12">
                        <b-button variant="success" :disabled="disabled" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button variant="danger" @click="modalRegistrarSimulacro = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>

        <template #footer>
            <span></span>
        </template>
    </CModal>

    <CModal :closeOnBackdrop="false" color="primario" size="lg" :show.sync="modalActualizarSimulacro">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Actualizar simulacro </span>
            </h6>
            <CButtonClose @click="modalActualizarSimulacro = false" class="text-white" />
        </template>

        <validation-observer ref="observer2" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(actualizarSimulacro)">
                <b-row>
                    <b-col md="3">
                        <validation-provider name="Simulacro N°" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Simulacro N°:" class="mb-2">
                                <b-form-input disabled v-model="datosActualizarSimulacro.numeroSimulacro" type="text" :state="getValidationState(validationContext)"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="9">
                        <validation-provider name="incidente" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Incidente:" class="mb-2">
                                <b-form-input v-model="datosActualizarSimulacro.incidente" type="text" :state="getValidationState(validationContext)" placeholder="Ingrese el incidente"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="actuaciones desarrolladas" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Actuaciones desarrolladas:" class="mb-2">
                                <b-form-textarea v-model="datosActualizarSimulacro.actuaciones" rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Ingrese las actuaciones"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="Desviaciones / Deficiencias / Daños detectados" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Desviaciones / Deficiencias / Daños detectados:" class="mb-2">
                                <b-form-textarea v-model="datosActualizarSimulacro.desviaciones" rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Ingrese las desviaciones"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4">
                        <validation-provider name="fecha" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Fecha:" class="mb-2">
                                <b-form-input v-model="datosActualizarSimulacro.fecha" type="date" :state="getValidationState(validationContext)"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4">
                        <validation-provider name="hora" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Hora:" class="mb-2">
                                <b-form-input v-model="datosActualizarSimulacro.hora" type="time" :state="getValidationState(validationContext)"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4">
                        <validation-provider name="responsable" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Responsable:" class="mb-2">
                                <b-form-input v-model="datosActualizarSimulacro.responsable" type="text" :state="getValidationState(validationContext)" placeholder="Ingrese el responsable"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="Medidas correctoras / Sugerencias" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Medidas correctoras / Sugerencias:" class="mb-2">
                                <b-form-textarea v-model="datosActualizarSimulacro.medidas" rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Ingrese las medidas correctivas"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="observaciones" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Observaciones:" class="mb-2">
                                <b-form-textarea v-model="datosActualizarSimulacro.observaciones" rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Ingrese las observaciones"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col class="my-2 text-right" md="12">
                        <b-button variant="success" :disabled="disabled" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button variant="danger" @click="modalActualizarSimulacro = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>

        <template #footer>
            <span></span>
        </template>
    </CModal>

</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

import Docxtemplater from "docxtemplater";
import {
    saveAs
} from "file-saver";
import ImageModule from "docxtemplater-image-module-free";
import JSZipUtils from "jszip-utils";
import JSZip from "jszip";

import expressions from 'angular-expressions';
import assign from "lodash.assign";

expressions.filters.lower = function (input) {
    // This condition should be used to make sure that if your input is
    // undefined, your output will be undefined as well and will not
    // throw an error
    if (!input) return input;
    return input.toLowerCase();
}

function angularParser(tag) {
    if (tag === '.') {
        return {
            get: function (s) {
                return s;
            }
        };
    }
    const expr = expressions.compile(
        tag.replace(/(’|‘)/g, "'").replace(/(“|”)/g, '"')
    );
    return {
        get: function (scope, context) {
            let obj = {};
            const scopeList = context.scopeList;
            const num = context.num;
            for (let i = 0, len = num + 1; i < len; i++) {
                obj = assign(obj, scopeList[i]);
            }
            return expr(scope, obj);
        }
    };
}

export default {
    data() {
        return {
            disabled: false,

            modalRegistrarSimulacro: false,
            modalActualizarSimulacro: false,

            totalRows: 1,
            currentPage: 1,
            filter: null,
            filterOn: [],
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],

            camposSimulacro: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                }, {
                    key: "numero",
                    label: "Simulacro N°",
                    class: "text-center",
                },
                {
                    key: "incidente",
                    label: "Incidente",
                    class: "text-center",
                },
                {
                    key: "fecha",
                    label: "Fecha",
                    class: "text-center",
                },
                {
                    key: "responsable",
                    label: "Responsable",
                    class: "text-center",
                },
                {
                    key: "opciones",
                    label: "",
                    class: "text-center"
                }
            ],
            listaSimulacros: [],
            datosSimulacro: {
                idCliente: null,
                ejercicio: JSON.parse(localStorage.settings).yExcs,
                numeroSimulacro: moment().format('MM-YYYY'),
                incidente: '',
                actuaciones: '',
                desviaciones: '',
                fecha: moment().format('YYYY-MM-DD'),
                hora: moment().format('HH:mm'),
                responsable: '',
                medidas: '',
                observaciones: ''
            },
            datosActualizarSimulacro: {
                idSimulacroSGMA: '',
                idCliente: '',
                ejercicio: JSON.parse(localStorage.settings).yExcs,
                numeroSimulacro: '',
                incidente: '',
                actuaciones: '',
                desviaciones: '',
                fecha: '',
                hora: '',
                responsable: '',
                medidas: '',
                observaciones: ''
            }
        }
    },
    methods: {
        checkPermissions(code, crud) {
            return this.$store.state.permissions.filter(x => x.codigo == code)[0][crud]
        },
        base64DataURLToArrayBuffer(dataURL) {
            const base64Regex = /^data:image\/(png|jpg|svg|svg\+xml);base64,/;
            if (!base64Regex.test(dataURL)) {
                return false;
            }
            const stringBase64 = dataURL.replace(base64Regex, "");
            let binaryString;
            if (typeof window !== "undefined") {
                binaryString = window.atob(stringBase64);
            } else {
                binaryString = new Buffer(stringBase64, "base64").toString("binary");
            }
            const len = binaryString.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
                const ascii = binaryString.charCodeAt(i);
                bytes[i] = ascii;
            }
            return bytes.buffer;
        },
        generarInforme(param) {
            let me = this;
            //me.swat('info', 'Generando reporte espere porfavor ...')
            me.disabled = true;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/obtener-logo-cliente", {
                        params: {
                            logo: JSON.parse(localStorage.settings).logo,
                        }
                    }, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        }
                    }
                )
                .then(function (response) {
           
                    JSZipUtils.getBinaryContent(`${CONSTANTES.URL_RUTA_FRONTEND}plantillas/plantilla-simulacro.docx`, function (error, content) {
                        if (error) {
                            console.error(error);
                            return;
                        }
                        var opts = {}
                        opts.centered = true;
                        const imageOpts = {
                            getImage(tag) {
                                return me.base64DataURLToArrayBuffer(tag);
                            },
                            getSize() {
                                return [150, 60];
                            },
                        };

                        var imageModule = new ImageModule(imageOpts);

                        var zip = new JSZip(content);
                        var doc = new Docxtemplater()
                            .loadZip(zip)
                            .setOptions({
                                parser: angularParser
                            })
                            .attachModule(imageModule);
                        doc.setData({
                            //image: response.data.datosAsistentes[0].urlFirma,
                            logo: response.data,
                            fecha: param.item.fecha,
                            numero: param.item.numero,
                            hora: param.item.hora,
                            incidente: param.item.incidente,
                            responsable: param.item.responsable,
                            actuacion: param.item.actuacion,
                            desviacion: param.item.desviacion,
                            medidasCorrectora: param.item.medidasCorrectora,
                            observacion: param.item.observacion,
                        });

                        try {
                            doc.render();
                        } catch (error) {
                            let e = {
                                message: error.message,
                                name: error.name,
                                stack: error.stack,
                                properties: error.properties
                            };
                            console.log(e)
                            throw error;
                        }
                        var out = doc.getZip().generate({
                            type: "blob",
                            mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                        });
                        saveAs(out, "informe-simulacro.docx");
                        //me.disabled = false;
                    });
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        registrarSimulacro() {
            let me = this;
            me.disabled = true;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-simulacro",
                    me.datosSimulacro, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    me.modalRegistrarSimulacro = false
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.listarSimulacros();
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },

        listarSimulacros() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-simulacros", {
                        params: {
                            idCliente: me.datosSimulacro.idCliente,
                            ejercicio: JSON.parse(localStorage.settings).yExcs
                        },
                    }
                )
                .then(function (response) {
                    me.listaSimulacros = response.data,
                        me.totalRows = me.listaSimulacros.length
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        actualizarSimulacro() {
            let me = this;
            me.disabled = true;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-simulacro",
                    me.datosActualizarSimulacro, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    me.modalActualizarSimulacro = false
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.listarSimulacros();
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },
        abrirModalActualizarSimulacro(param) {
            let me = this;
            this.datosActualizarSimulacro.idSimulacroSGMA = param.item.idSimulacroSGMA;
            this.datosActualizarSimulacro.numeroSimulacro = param.item.numero;
            this.datosActualizarSimulacro.incidente = param.item.incidente;
            this.datosActualizarSimulacro.actuaciones = param.item.actuacion;
            this.datosActualizarSimulacro.desviaciones = param.item.desviacion;
            this.datosActualizarSimulacro.fecha = param.item.fecha;
            this.datosActualizarSimulacro.hora = param.item.hora;
            this.datosActualizarSimulacro.responsable = param.item.responsable;
            this.datosActualizarSimulacro.medidas = param.item.medidasCorrectora;
            this.datosActualizarSimulacro.observaciones = param.item.observacion;
            this.datosActualizarSimulacro.ejercicio = param.item.ejercicio;
            me.modalActualizarSimulacro = true;
        },
        resetModalRegistrarSimulacro() {
            this.$nextTick(() => {
                this.$refs.observer.reset();
            });

            this.datosSimulacro.incidente = '';
            this.datosSimulacro.actuaciones = '';
            this.datosSimulacro.desviaciones = '';
            this.datosSimulacro.fecha = moment().format('YYYY-MM-DD');
            this.datosSimulacro.hora = moment().format('HH:mm');
            this.datosSimulacro.responsable = '';
            this.datosSimulacro.medidas = '';
            this.datosSimulacro.observaciones = '';
        },
        resetModalActualizarSimulacro() {
            this.$nextTick(() => {
                this.$refs.observer2.reset();
            });

        },
        eliminarSimulacro(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar el simulacro?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    let me = this;
                    axios
                        .post(
                            CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/eliminar-simulacro", {
                                idSimulacroSGMA: param.item.idSimulacroSGMA
                            }, {
                                headers: {
                                    Authorization: `Bearer ${localStorage.token}`
                                },
                            }
                        )
                        .then(function (response) {
                            let color = response.data.resultado == 1 ? "success" : "error";
                            me.swat(color, response.data.mensaje)
                            me.listarSimulacros();
                        })
                        .catch(function (error) {
                            me.swat('error', 'Algo salió mal!')
                        });
                }
            })
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        }
    },
    computed: {

    },
    watch: {
        modalRegistrarSimulacro: function (val) {
            if (val == false) {
                this.resetModalRegistrarSimulacro();
            }
        },
        modalActualizarSimulacro: function (val) {
            if (val == false) {
                this.resetModalActualizarSimulacro();
            }
        }
    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosSimulacro.idCliente = this.datosActualizarSimulacro.idCliente = user.uidClient;
            this.listarSimulacros();
        }
    }

}
</script>
